// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import User from '@/objects/User'

export default class SessionService {
  static getLoggedInUser() {
    return store.getters['session/user']
  }

  static isAdmin() {
    return this.getLoggedInUser().isAdmin()
  }

  static logout() {
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

    // Remove userData from localStorage
    store.commit('session/updateUser', User.blankUser())

    // Redirect to login page
    if (router.currentRoute.path !== '/login') {
      router.push({ path: '/login' })
    }
  }
}
