// eslint-disable-next-line import/no-cycle
import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'

const jwtConfig = {
  // Endpoints
  loginEndpoint: '/auth/login',
  registerEndpoint: '/account/setup',
  forgotPasswordEndpoint: '/account/forgot-password',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
}

const { jwt } = useJwt(axios, jwtConfig)
export default jwt
