import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import jwt from '@/auth/jwt/useJwt'
import store from '@/store'
import User from '@/objects/User'
import routes from './routes'

Vue.use(VueRouter)

const loggedIn = (jwt.getToken() != null)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (loggedIn) {
      // eslint-disable-next-line consistent-return
      store.dispatch('session/refreshUser').then(response => {
        let user
        try {
          user = new User()
          user.updateUserFromRefresh(response.data)
          store.commit('session/updateUser', user)
        } catch (e) {
          user = null
          store.commit('session/updateUser', user)

          return { name: 'login' }
        }
      })
      next()
    } else {
      next({ path: '/login' })
    }
  } else {
    next()
  }
})

export default router
