export default class RoleTypes {
    static Admin = new RoleTypes('admin');

    static Worker = new RoleTypes('worker');

    static Customer = new RoleTypes('customer');

    constructor(role) {
      this.role = role
    }

    static roleTypes() {
      return [
        { label: 'Admin', value: RoleTypes.Admin.get() },
        { label: 'Worker', value: RoleTypes.Worker.get() },
        { label: 'Customer', value: RoleTypes.Customer.get() },
      ]
    }

    get() {
      return this.role
    }

    display() {
      return this.role ? RoleTypes.roleTypes().find(r => r.value === this.role)?.label : null
    }
}
