import RoleTypes from '@/enums/RoleTypes'

export default class User {
    // Variables
    username = null

    firstName = null

    lastName = null

    avatarUrl = null

    role = new RoleTypes(RoleTypes.Customer)

  canReceiveDeliveries = false

  // Constructor
  constructor(data = null) {
    if (data != null) {
      this.username = data.username
      this.firstName = data.first_name
      this.lastName = data.last_name
      this.avatarUrl = data.avatar_url
      this.role = new RoleTypes(data.role)
      this.canReceiveDeliveries = +data.user_can_receive_deliveries === 1
    }
  }

  updateUserFromRefresh(data) {
    if (data != null) {
      this.username = data.username
      this.firstName = data.user_first_name
      this.lastName = data.user_last_name
      this.avatarUrl = data.user_avatar_url
      this.role = new RoleTypes(data.user_role)
      this.canReceiveDeliveries = +data.user_can_receive_deliveries === 1
    }
  }

  isAdmin() {
    return this.role.get() === RoleTypes.Admin.get()
  }

  static blankUser() {
    return {
      username: null,
      firstName: null,
      lastName: null,
      avatarUrl: null,
      role: new RoleTypes(RoleTypes.Customer),
      canReceiveDeliveries: false,
    }
  }
}
