import axios from '@axios'
import User from '@/objects/User'

export default {
  namespaced: true,
  state: {
    user: new User(),
  },
  getters: {
    user: state => state.user,
  },
  mutations: {
    updateUser(state, val) {
      state.user = val
    },
  },
  actions: {
    refreshUser() {
      return new Promise((resolve, reject) => {
        axios.get('/user')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
