export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/items',
    name: 'items',
    component: () => import('@/views/Items.vue'),
    meta: {
      pageTitle: 'Items',
      breadcrumb: [
        {
          text: 'Items',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/item-groups',
    name: 'item-groups',
    component: () => import('@/views/ItemGroups.vue'),
    meta: {
      pageTitle: 'Item Groups',
      breadcrumb: [
        {
          text: 'Item Groups',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/item-groups/:item_group_id',
    name: 'item-group',
    component: () => import('@/views/ItemGroup.vue'),
    meta: {
      pageTitle: 'Item Group',
      breadcrumb: [
        {
          text: 'Item Groups',
          active: false,
          to: '/item-groups',
        },
        {
          text: 'Item Group',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/AdminDashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/Orders.vue'),
    meta: {
      pageTitle: 'Orders',
      breadcrumb: [
        {
          text: 'Orders',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/orders/:order_id',
    name: 'shop',
    component: () => import('@/views/shop/Order.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Build',
      breadcrumb: [
        {
          text: 'Orders',
          active: false,
          to: '/orders',
        },
        {
          text: 'Shop',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/orders/:order_id/sign',
    name: 'shop',
    component: () => import('@/views/OrderSignature.vue'),
    meta: {
      layout: 'full',
      requiresAuth: true,
    },
  },
  {
    path: '/locations',
    name: 'locations',
    component: () => import('@/views/locations/Locations.vue'),
    meta: {
      pageTitle: 'Locations',
      breadcrumb: [
        {
          text: 'Locations',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/categories',
    name: 'item-categories',
    component: () => import('@/views/categories/Categories.vue'),
    meta: {
      pageTitle: 'Categories',
      breadcrumb: [
        {
          text: 'Categories',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/locations/:location_id',
    name: 'location',
    component: () => import('@/views/locations/Location.vue'),
    meta: {
      pageTitle: 'Location',
      breadcrumb: [
        {
          text: 'Locations',
          active: false,
          to: '/locations',
        },
        {
          text: 'Location',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/fulfillment',
    name: 'fulfillment',
    component: () => import('@/views/fulfillment/Fulfillment.vue'),
    meta: {
      pageTitle: 'Fulfillment',
      breadcrumb: [
        {
          text: 'Fulfillment',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/discountsAndFees-fees',
    name: 'discounts&fees',
    component: () => import('@/views/discountsAndFees/DiscountsAndFees.vue'),
    meta: {
      pageTitle: 'Discounts & Fees',
      breadcrumb: [
        {
          text: 'Discounts & Fees',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false,
    },
  },
  {
    path: '/account-setup/complete',
    name: 'complete',
    component: () => import('@/views/CompleteAccount.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false,
      type: 'setup',
    },
  },
  {
    path: '/forgot-password/complete',
    name: 'complete',
    component: () => import('@/views/CompleteAccount.vue'),
    meta: {
      layout: 'full',
      requiresAuth: false,
      type: 'password',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/app-settings',
    name: 'app-settings',
    component: () => import('@/views/appSettings/AppSettings.vue'),
    meta: {
      pageTitle: 'App Settings',
      breadcrumb: [
        {
          text: 'App Settings',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
]
