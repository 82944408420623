import Vue from 'vue'

// axios
import axios from 'axios'

let baseURL = 'https://api.trufencesupply.com'

if (process.env.NODE_ENV === 'development') {
  baseURL = 'http://localhost:8001'
}

const axiosIns = axios.create({
  baseURL,
})

Vue.prototype.$http = axiosIns

export default axiosIns
