// eslint-disable-next-line import/no-cycle,import/no-named-as-default,import/no-named-as-default-member
import JwtService from './jwtService'

export default function useJwt(axiosIns, jwtOverrideConfig) {
  const jwt = new JwtService(axiosIns, jwtOverrideConfig)

  return {
    jwt,
  }
}
